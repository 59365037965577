<template>
  <b-card-body :class="{'loading': loading}">
    <route-money-distribution-report-table
      :data="data"
      :period="filter.period"
    />
    <b-row>
      <b-col
        lg="12"
      >
        <route-money-distribution-report-chart
          :data="data"
          :period="filter.period"
        />
      </b-col>
    </b-row>
  </b-card-body>
</template>

<script>
import RouteMoneyDistributionReportTable from './RouteMoneyDistributionReportTable';
import {routesMoneyDistributionStatistic} from '@/services/api';
import RouteMoneyDistributionReportChart from './RouteMoneyDistributionReportChart';
export default {
  name: 'RouteMoneyDistributionReport',
  components: {RouteMoneyDistributionReportChart, RouteMoneyDistributionReportTable},
  props: {
    filter: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      data: [],
      total: {},
      loading: true,
    };
  },
  watch: {
    filter: {
      handler: function(newVal) {
        this.routesMoneyDistributionData(newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.routesMoneyDistributionData(this.filter);
  },
  methods: {
    async routesMoneyDistributionData(data) {
      this.loading = true;
      const response = await routesMoneyDistributionStatistic(this.$store.state.user.ownerId, data);
      if (response && response.status === 200) {
        this.data = response.data.items.map((item)=>{
          return {
            date: item.date,
            auctions: (item.auctions/1000000).toFixed(2),
            tenders: (item.tenders/1000000).toFixed(2),
            nowhere: (item.nowhere/1000000).toFixed(2),
            total: (item.total/1000000).toFixed(2),
          };
        });
        this.total = response.data.total;
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>

</style>
